<template>
    <div class="school-container">
        <div class="school-banner">
            <div class="school-banner-text">
                <div class="school-banner-text-top">优学派智慧校园解决方案</div>
                <div class="school-banner-text-bottom">
                    <span v-for="(item, index) in texts" :key="index">{{item}}</span>
                </div>
            </div>
        </div>
        <div class="content">
            <div
                class="img"
                v-for="item in 14"
                :key="item"
                :style="getStyle(item)"
            >
                <img :src="require(`./images/icon_content_s_${item}.png`)" >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'School',
    data () {
        return {
            texts: ['个性化定制', '安全校园', '科学管理', '数字化反馈', '智能化服务'],
        };
    },
    methods: {
        getStyle (index) {
            let imgUrl = require(`./images/icon_bg_s_${index}.png`);
            return { 'background-image': 'url(' + imgUrl + ')' };
        },
    },
};
</script>
<style scoped lang="scss">
    .school-container {
        .school-banner {
            width: 100%;
            height: 375px;
            background-image: url('./images/icon_banner_school.png');
            background-repeat: no-repeat;
            // background-size: 2293px 375px;
            background-position: center;
            display: flex;
            align-items: center;
            &-text {
                margin-bottom: 60px;
                margin-left: 20%;
                &-top {
                    font-size:40px;
                    color:#ffffff;
                    line-height:72px;
                    margin-bottom: 28px;
                }
                &-bottom {
                    font-size:22px;
                    color:#fbfbf4;
                    line-height:45px;
                    span {
                        margin-right: 20px;
                    }
                }
            }
        }
        .content {
            .img {
                text-align: center;
                padding: 60px 0;
                background-repeat: no-repeat;
                background-size: cover;
                img {
                    max-width: 1024px;
                }
            }
        }
    }
</style>
